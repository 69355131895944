import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import { graphql } from 'gatsby';
import Section from "~components/Section"
import ContactForm from "~components/blocks/ContactForm"
import EnquiryForm from "~components/blocks/EnquiryForm"
import CTASection from "~components/sections/CTASection"
import resolveLink from '../utils/resolveLink';

const Contact = ( data ) => {

  const{ mobile } = breakpoints

  const contact = data?.data?.sanityContact
  const contactContent = data?.data?.sanityContact?._rawContent
  const showrooms = data?.data?.sanityShowrooms?._rawContent?.showrooms
  
  return (
    <>
      <Seo
        title={contact?.title}
        image={contact?.content?.seo?.socialImage?.asset?.url}
        description={contact?.content?.seo?.metaDescription}
        metaTitle={contact?.content?.seo?.metaTitle}
				url="/contact"
      />
      <Section
        css={css`
          padding-top: 22px;
          ${mobile}{
            padding-top: 16px;
          }
        `}
      >
        <div css={css`
          grid-column: 1/3;
          ${mobile}{
            grid-column: span 12;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 10px;
          }
        `}>
          <h4 css={css`
            margin-bottom: 78px;
            ${mobile}{
              grid-column: span 12;
              margin-bottom: 42px;
            }
          `}>
            {contact?.title}
          </h4>
          {showrooms?.map(showroom => (
            <div
              key={showroom?._key}
              css={css`
                margin-bottom: 60px;
                ${mobile}{
                  grid-column: span 6;
                  margin-bottom: 58px;
                }
            `}>
              <h6 css={css`margin-bottom: 14px;`}>{showroom?.title}</h6>
              <div className="h6">{showroom?.address}</div>
              <div className="h6">{showroom?.city} {showroom?.state} {showroom?.postCode}</div>
              <div className="h6">{showroom?.country}</div>
              <div className="h6">{showroom?.phoneNumber}</div>
            </div>
          ))}
        </div> 
        <div css={css`
          grid-column: 4/10;
          ${mobile}{
            grid-column: span 12;
          }
        `}>
          <h4 css={css`margin-bottom: 60px;${mobile}{margin-bottom: 26px;}`}>Contact Us</h4>
          <ContactForm 
            formName={'Contact'}
            dropdownBackground={'var(--beige)'}
            successMessage={contact.content?.contactSuccessMessage}
            css={css`margin-bottom: 176px;${mobile}{margin-bottom: 108px;}`}
          />
          {/* <h4 css={css`margin-bottom: 60px;${mobile}{margin-bottom: 26px;}`}>Consultation Enquiry</h4>
          <EnquiryForm 
            formName={'Enquiry'}
            dropdownBackground={'var(--beige)'}
            successMessage={contact.content?.consultationEnquirySuccessMessage}
            css={css`margin-bottom: 308px;${mobile}{margin-bottom: 156px;}`}
          /> */}
        </div>
      </Section>
      <CTASection
        title={contactContent.cta?.title}
        image={contactContent.cta?.image} 
				text={contactContent.cta?.text} 
        link={contactContent.cta?.link?.to ? resolveLink(contactContent.cta?.link?.to?._type, contactContent.cta?.link?.to?.slug?.current) : undefined}
        buttonText={contactContent.cta?.link?.linkText}  
      />
    </>
  )
}


export const query = graphql`
  query{
    sanityContact {
      _rawContent
      title
      content {
        seo {
          socialImage {
            asset {
              url
            }
          }
          metaDescription
          metaTitle
        }
        contactSuccessMessage {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
        consultationEnquirySuccessMessage {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
      }
    } 
    sanityShowrooms {
      _rawContent
    }
  }
`
export default Contact
